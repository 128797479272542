import React from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useQuery } from '@apollo/client'
import { mainCategoryMap } from 'Components/CaseManagement/helpers'
import { GET_CASES } from 'Components/ClientPortal/MyBenefits/gql'
import MainContent from 'Components/ClientPortal/MyBenefits/MainContent/MainContent'
import { selectBenefitModalProps } from 'Components/ClientPortal/selectors'
import ClientPortalPageTemplate from 'Components/ClientPortal/Shared/ClientPortalPageTemplate'
import BenefitTooltipDialogForClientPortal from 'Components/Shared/BenefitTooltipDialog/BenefitTooltipDialogForClientPortal/BenefitTooltipDialogForClientPortal'
import { renderCategoryIcon } from 'Pages/Results/Eligibility'
import { closeBenefitInfoModal } from 'Reducers/myBenefitsSlice'
import categoryIconPathsMap from 'Shared/Icons/CategoryIcons'
import { useAppSelector } from 'Store/hooks'

const MyBenefits = () => {
  const dispatch = useDispatch()
  const id = useAppSelector((state) => state.client.id)
  const {
    isBenefitModalOpen,
    openEligibilityKey,
    benefitName,
    benefitDescription,
  } = useSelector((state) => selectBenefitModalProps(state))
  const { data, loading, error } = useQuery(GET_CASES, {
    variables: {
      id,
    },
    skip: !id,
  })

  return (
    <ClientPortalPageTemplate>
      <>
        <BenefitTooltipDialogForClientPortal
          isTabletAndMobile={false}
          handleClose={() => {
            dispatch(closeBenefitInfoModal())
          }}
          open={isBenefitModalOpen}
          name={benefitName}
          category={
            mainCategoryMap[openEligibilityKey as keyof typeof mainCategoryMap]
              ?.name
          }
          categoryIcon={renderCategoryIcon(
            categoryIconPathsMap[openEligibilityKey]
          )}
          description={benefitDescription}
        />
        <MainContent data={data} loading={loading} error={error} />
      </>
    </ClientPortalPageTemplate>
  )
}
export default MyBenefits
