import React from 'react'

import { StyledTabletAndDesktopSearchBar } from './style'

interface SearchBarProps {
  children: React.ReactNode
  className?: string
}
const SearchBar = ({ children, className }: SearchBarProps) => (
  <StyledTabletAndDesktopSearchBar className={className}>
    {children}
  </StyledTabletAndDesktopSearchBar>
)

export default SearchBar
