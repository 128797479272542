import { gql } from '__generated__/gql'

export const GET_CASES = gql(`
    query getCases ($id: ID!) {
        client(id: $id) {
            id
            clientLocations {
                id
                serviceCases {                   
                    id
                    benefitStatus
                    eligibility                                     
                    activationStatus
                    service {
                        toolkitUrl
                        id 
                        name
                        mainCategory {
                            openEligibilityKey
                        }
                        description
                    }
                    status
                }
            }
        }
    }
`)

export const ACTIVATE_INACTIVE_CASE = gql(`
    mutation ActivateInactiveCase($serviceCaseId: ID!) {
        activateInactiveServiceCase(serviceCaseId: $serviceCaseId) {
            serviceCase {
                activationStatus
                alreadyReceiving
                benefitStatus
                closed
                createdAt
                drawdownAmount
                eligibility
                eligibleAmount
                id
                status
                updatedAt
            }
        }
    }
`)
