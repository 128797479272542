import { createSelector } from '@reduxjs/toolkit'

export const selectBenefitModalProps = createSelector(
  [
    (state) => state.myBenefits.openBenefitInfoModal,
    (state) => state.myBenefits.openEligibilityKey,
    (state) => state.myBenefits.benefitName,
    (state) => state.myBenefits.benefitDescription,
  ],
  (isBenefitModalOpen, openEligibilityKey, benefitName, benefitDescription) => {
    return {
      isBenefitModalOpen,
      openEligibilityKey,
      benefitName,
      benefitDescription,
    }
  }
)
