import type {
  Activity,
  ExternalReferral,
  Location,
  NoteAttachment,
  NoteInput,
  NoteType,
  OutcomeType,
  Service,
  ServiceCase,
  ServiceCaseClosure,
} from '__generated__/graphql'

type BaseActivity = Activity & {
  actionable: NoteInput
}

export type ClientTransferActivity = BaseActivity & {
  actionable: {
    attachments: NoteAttachment[]
    originalLocation: Location
    targetLocation: Location
  }
}

export type ScreeningCalculationActivity = BaseActivity

export type NoteActivity = BaseActivity & {
  actionable: {
    attachments: NoteAttachment[]
    serviceCase: ServiceCase
    pinnedBy?: {
      fullName: string
    }
  }
}

export type ServiceCaseActivity = BaseActivity & {
  actionable: {
    attachments: NoteAttachment[]
    service: Service
    serviceCaseClosure: ServiceCaseClosure
  }
}

// create an enum for the different types of service case partial outcome confirmationTypes
export enum ServiceCasePartialOutcomeConfirmationType {
  YES = 'YES',
  NO = 'NO',
  UNKNOWN = 'UNKNOWN',
  NOT_YET = 'NOT_YET',
}

export type ServiceCaseFollowupActivity = BaseActivity & {
  actionable: {
    attachments: NoteAttachment[]
    serviceCaseName: { service: Service }
    partialOutcome: {
      activityType: string
      confirmationType: ServiceCasePartialOutcomeConfirmationType
      confirmedAmount: number
      outcomeType: OutcomeType
    }
    serviceCase: { service: Service }
    outcomeType: OutcomeType
    noteType: NoteType
    status: string
  }
}

export type ReferralActivity = Activity & {
  actionable: ExternalReferral & {
    referredService: Service
  }
}
