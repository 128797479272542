import type { PayloadAction } from '@reduxjs/toolkit'
import { createAction, createSlice } from '@reduxjs/toolkit'
import { myBenefits as initialState } from 'Reducers/initialState'
import { REMEMBER_PERSISTED, REMEMBER_REHYDRATED } from 'redux-remember'

const rememberRehydrated = createAction<{ ui: typeof initialState }>(
  REMEMBER_REHYDRATED
)

export type CaseInfoModalPayload = {
  toolkitUrl?: string
  benefitDescription: string
  openEligibilityKey: string
  benefitName: string
}

export type OpenEditBenefitStatusModalPayload = {
  benefitName: string
}

export const myBenefitsSlice = createSlice({
  name: 'myBenefits',
  initialState,
  reducers: {
    openBenefitInfoModal: (
      state,
      action: PayloadAction<CaseInfoModalPayload>
    ) => {
      state.openBenefitInfoModal = true
      state.toolkitUrl = action.payload.toolkitUrl
      state.benefitDescription = action.payload.benefitDescription
      state.benefitName = action.payload.benefitName
      state.openEligibilityKey = action.payload.openEligibilityKey
    },
    closeBenefitInfoModal: (state) => {
      state.openBenefitInfoModal = false
      state.toolkitUrl = ''
      state.benefitDescription = ''
      state.benefitName = ''
      state.openEligibilityKey = ''
    },
    openEditBenefitStatusModal: (
      state,
      action: PayloadAction<OpenEditBenefitStatusModalPayload>
    ) => {
      state.openEditCaseStatusModal = true
      state.benefitName = action.payload.benefitName
      state.benefitStatus = action.payload.benefitStatus
      state.caseId = action.payload.caseId
    },
    closeEditBenefitStatusModal: (state) => {
      state.openEditCaseStatusModal = false
      state.benefitName = ''
      state.benefitStatus = ''
      state.caseId = ''
    },
  },
  extraReducers: (builder) => {
    builder.addCase(rememberRehydrated, (state, action) => {
      return {
        ...state,
        ...action.payload.ui,
        rehydrated: true,
      }
    })
    builder.addCase(REMEMBER_PERSISTED, (state) => {
      state.rehydrated = false
      state.persisted = true
    })
  },
})
export const {
  openBenefitInfoModal,
  closeBenefitInfoModal,
  openEditBenefitStatusModal,
  closeEditBenefitStatusModal,
} = myBenefitsSlice.actions
export default myBenefitsSlice.reducer
