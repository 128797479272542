import React from 'react'
import { useMediaQuery } from 'react-responsive'
import Icon from 'Components/Shared/Icon/Icon'
import { MOBILE_AND_TABLET_MEDIA_QUERY } from 'Shared/constants'
import categoryIconPathsMap from 'Shared/Icons/CategoryIcons/CategoryIconPathsMap'

import {
  StyledCategoryFilterItem,
  StyledCategoryFilterItemLabel,
} from './style'

type Category = {
  label: string
  children?: Category[]
}

type CategoryFilterItemProps = {
  category: {
    label: string
    children: Category[]
    description: string
  }
  id: number
}

const CategoryFilterItemMegaMenu = (props: CategoryFilterItemProps) => {
  const { category, id } = props
  const { label } = category
  const iconPath = categoryIconPathsMap[id as keyof typeof categoryIconPathsMap]
  const isMobileOrTablet = useMediaQuery({
    query: MOBILE_AND_TABLET_MEDIA_QUERY,
  })
  return (
    <StyledCategoryFilterItem tabIndex={-1}>
      <Icon
        key={label}
        scale={isMobileOrTablet ? '2rem' : '1.5rem'}
        margin={'0 0.625rem 0 0'}
        screenReaderLabel={label}
        fill='auto'
      >
        {iconPath}
      </Icon>
      <StyledCategoryFilterItemLabel htmlFor={label}>
        {label}
      </StyledCategoryFilterItemLabel>
    </StyledCategoryFilterItem>
  )
}

export default CategoryFilterItemMegaMenu
