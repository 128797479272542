import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'
import { useDynamicRegistrationUrl } from 'Components/App/Hooks'
import useRegisterUrl from 'Components/App/Hooks/useRegisterUrl'
import useLogOut from 'Components/Auth/Hooks/useLogOut'
import {
  Cases,
  localIconPath,
  reportIconPath,
} from 'Components/CaseManagement/Reporting/ReportingSidebar/sideIcons'
import ResourcesIcon from 'Components/ClientPortal/MyResources/ResourcesIcon'
import { destroy } from 'redux-form'
import {
  CUSTOM_MOBILE_TABLET_MEDIA_QUERY,
  PRE_SCREENER_FORM_NAME,
} from 'Shared/constants'
import { getUserType, isJestTest } from 'Shared/helpers'
import aboutIconPath from 'Shared/Icons/MenuList/aboutIconPath'
import browseAllBenefitsIconPath from 'Shared/Icons/MenuList/browseAllBenefitsIconPath'
import emailIconPath from 'Shared/Icons/MenuList/emailIconPath'
import loginIconPath from 'Shared/Icons/MenuList/loginIconPath'
import personalizedBenefitsIconPath from 'Shared/Icons/MenuList/personalizedBenefitsIconPath'
import preferredLanguageIconPath from 'Shared/Icons/MenuList/preferredLanguageIconPath'
import printIconPath from 'Shared/Icons/MenuList/printIconPath'
import profileIconPath from 'Shared/Icons/MenuList/profileIconPath'
import signUpPath from 'Shared/Icons/MenuList/signUpPath'

export const getClientSummaryRoute = (clientLocationId) => ({
  iconPath: browseAllBenefitsIconPath,
  text: 'Client Summary',
  path: `/case-management/clients/${clientLocationId}`,
})

const useMenuItemsData = () => {
  const dispatch = useDispatch()
  const { handleLogOut } = useLogOut()
  const userType = getUserType()
  const navigate = useNavigate()

  const registerUrl = useDynamicRegistrationUrl()
  const isCustomMobileTablet = useMediaQuery({
    query: CUSTOM_MOBILE_TABLET_MEDIA_QUERY,
  })

  const isScreenerCompleted = useSelector(
    (state) => state.client.screenerCompleted
  )

  const eligibilityNavText = () => {
    return isScreenerCompleted ? 'Eligibility Results' : 'Find My Eligibility'
  }

  const accountData = [
    {
      iconPath: loginIconPath,
      viewBox: '0 0 32 32',
      greenColor: true,
      text: 'Log in',
      path: '/login',
      onClick: () => dispatch(destroy(PRE_SCREENER_FORM_NAME)),
    },
    {
      iconPath: signUpPath,
      viewBox: '0 0 32 32',
      greenColor: true,
      text: 'Sign up',
      path: registerUrl,
      primary: 'true',
    },
  ]

  const logoutData = [
    {
      iconPath: preferredLanguageIconPath,
      text: 'Log out',
      onClick: () =>
        handleLogOut().then(({ data, graphQLErrors }) => {
          if (data) {
            localStorage.setItem('hasLoggedOut', 'true')
            navigate(
              userType === 'Case Manager' ? '/case-management/login' : '/logout'
            )
          } else {
            if (!isJestTest()) console.error(graphQLErrors)
            navigate(
              getUserType === 'Case Manager'
                ? '/case-management/login'
                : '/login'
            )
          }
        }),
    },
  ]

  const fullMenuData = [
    {
      iconPath: personalizedBenefitsIconPath,
      viewBox: '0 0 32 32',
      text: eligibilityNavText(),
      path: useRegisterUrl(registerUrl),
    },
    {
      iconPath: browseAllBenefitsIconPath,
      viewBox: '0 0 32 32',
      text: 'Browse All Benefits & Resources',
      path: '/locations/list',
    },

    {
      iconPath: aboutIconPath,
      viewBox: '0 0 32 32',
      text: 'About',
      externalUrl: 'https://singlestop.org/',
    },
  ]

  const tabletTopNavMenuData = [
    {
      iconPath: personalizedBenefitsIconPath,
      text: eligibilityNavText(),
      path: useRegisterUrl(registerUrl),
    },
    {
      iconPath: browseAllBenefitsIconPath,
      text: 'Browse Resources',
      path: '/locations/list',
    },
    {
      iconPath: aboutIconPath,
      text: 'About',
      externalUrl: 'https://singlestop.org/',
    },
  ]

  const nextStepsTopNavMenuData = [
    { iconPath: emailIconPath, text: 'Email' },
    { iconPath: printIconPath, text: 'Print' },
  ]

  const caseManagementTopNavMenuData = [
    {
      iconPath: Cases,
      text: 'Client Records',
      path: '/case-management/clients',
    },
    {
      iconPath: localIconPath,
      text: 'Browse Resources',
      path: '/locations/list',
    },
    {
      iconPath: reportIconPath,
      text: 'Reports',
      path: '/case-management/reports/demographics/overview',
    },
  ]

  const menuItemsLoggedInAsCM = [
    {
      iconPath: browseAllBenefitsIconPath,
      text: 'Client Records',
      path: '/case-management/clients',
    },
    {
      iconPath: browseAllBenefitsIconPath,
      text: 'Browse Resources',
      path: '/locations/list',
    },
    {
      iconPath: aboutIconPath,
      text: 'About',
      externalUrl: 'https://singlestop.org/',
    },
  ]

  const clientPortalData = isCustomMobileTablet
    ? [
        {
          iconPath: profileIconPath,
          text: 'My Profile',
          path: '/account/profile',
        },
        {
          iconPath: personalizedBenefitsIconPath,
          text: 'My Benefits',
          path: '/account/benefits',
        },
        {
          iconPath: ResourcesIcon,
          text: 'My Resources',
          path: '/account/resources',
        },
      ]
    : [
        {
          iconPath: profileIconPath,
          text: 'My Account',
          path: '/account/profile',
        },
      ]

  return {
    accountData,
    logoutData,
    fullMenuData,
    tabletTopNavMenuData,
    nextStepsTopNavMenuData,
    caseManagementTopNavMenuData,
    menuItemsLoggedInAsCM,
    clientPortalData,
  }
}

export default useMenuItemsData
