import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
  ClassNameMap,
  CSSProperties,
} from '@material-ui/core/styles/withStyles'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import theme from 'Shared/Theme/ssTheme'
import styled, { css } from 'styled-components'

import { ServiceCasePartialOutcomeConfirmationType } from './RecentActivityCards/Activity'

const StyledActivity = css`
  display: flex;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.5;
  padding: 5px;
  p {
    margin-top: 0;
    margin-bottom: 0rem;
    transition: all 0.5s;
  }

  & > div svg {
    transition: all 0.5s;
  }
`

type StyledRecentActivityProps = CSSProperties & {
  disableModal?: boolean
}

export const StyledRecentActivity = styled.div<StyledRecentActivityProps>`
  ${StyledActivity}
  position: relative;
  transition: all 0.5s;
  width: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor || '#fff'};
  padding-left: 12px;
  margin-bottom: 4px;
  border-radius: 10px;
  &:hover {
    background-color: #eef0f5;
  }
  cursor: ${({ disableModal }) => (disableModal ? 'default' : 'pointer')};
`

export const StyledFirstActivity = styled.div`
  ${StyledActivity}
`

export const StyledRecentActivityHouseholdIndividuals = styled.span``

export const StyledRecentActivityData = styled.div``

export const StyledRecentActivityDataHeading = styled.p`
  font-size: 0.75rem;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
`

export const StyledRecentActivityDataName = styled.p`
  color: ${theme.palette.primary.dark};
  font-size: 0.75rem;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
`

type StyledRecentActivityDataEventProps = {
  hasAttachment?: boolean
}

export const StyledRecentActivityDataEvent = styled.p<StyledRecentActivityDataEventProps>`
  font-weight: 700;
  ${(props) =>
    props.hasAttachment &&
    css`
      position: relative;
      left: -4px;
    `}
`

export const StyledActivityDate = css`
  position: relative;
  color: ${theme.palette.text.default};
  font-size: 0.75rem;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
`

export const StyledFirstActivityDate = styled.div`
  ${StyledActivityDate}
`

export const StyledRecentActivityDate = styled.div`
  ${StyledActivityDate}
`
export const StyledRecentActivityContent = styled.div`
  position: relative;
  display: flex;
  padding-top: 0.875rem;
  flex-direction: column;
`

export const BoxIcon = styled.div`
  width: auto;
  height: 2.5rem;
  margin: 0 1.25rem 0 -0.3125rem;
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 0px 3px 6px #1d283117;
  padding: 0.4375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 99;
`
export const BackgroundIcon = styled.div<CSSProperties>`
  width: 1.75rem;
  height: 1.75rem;
  background-color: ${(props) => props.backgroundColor};
  opacity: 0.7;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${(props) => props.transform};
`

type StyledRecentActivityIconProps = {
  IconComponent: ({ classes }: { classes: ClassNameMap }) => JSX.Element
}

export const StyledRecentActivityIcon = ({
  IconComponent,
}: StyledRecentActivityIconProps) => {
  const StyledRecentActivityIcon = withStyles({
    root: {
      color: '#000',
      fontSize: '1.25rem',
      backgroundColor: 'unset',
    },
  })(IconComponent)
  return <StyledRecentActivityIcon />
}

export const StyledAttachmentIcon = withStyles({
  root: {
    fontSize: '14px',
    position: 'relative',
    top: '.2rem',
  },
})(AttachFileIcon)

type StyledSpanProps = {
  $closedCase?: boolean
  $denied?: boolean
  $acknowledged?: boolean
}

export const StyledSpan = styled.span<StyledSpanProps>`
  color: ${theme.palette.primary.dark};
  ${({ $closedCase }) =>
    $closedCase &&
    css`
      color: ${theme.palette.text.Lighten10};
    `};
  ${({ $denied }) =>
    $denied &&
    css`
      color: ${theme.palette.color.boldRed};
    `};
  ${({ $acknowledged }) =>
    $acknowledged &&
    css`
      color: ${theme.palette.primary.darken40};
    `};
  ${({ $activity }) =>
    $activity &&
    css`
      color: #49494b;
      font-weight: 700;
    `};
`

export const BlockSpan = styled.span`
  display: block;
  font-family: 'Roboto', sans-serif;
  color: ${theme.palette.text.default};
`

export const StyledServiceCaseFollowupActivityActivityDataHeading = styled.p`
  font-size: 0.75rem;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  color: #464b53;
`

type StyledServiceCaseFollowupActivityActivityOutcomeTypeProps = {
  denied: ServiceCasePartialOutcomeConfirmationType
}

export const StyledServiceCaseFollowupActivityActivityOutcomeType = styled.span<StyledServiceCaseFollowupActivityActivityOutcomeTypeProps>`
  font-size: 0.75rem;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  ${(props) => {
    switch (props.denied) {
      case ServiceCasePartialOutcomeConfirmationType.NO:
        return css`
          color: ${theme.palette.color.boldRed};
        `
      case ServiceCasePartialOutcomeConfirmationType.YES:
        return css`
          color: #0c7958;
        `
      default:
        return css`
          color: inherit;
        `
    }
  }}
`
export const StyledServiceCaseFollowupActivityActivityCTA = styled.span`
  display: block;
`

export const StyledAccordion = withStyles({
  root: {
    boxShadow: 'none',
  },
})(Accordion)

export const StyledAccordionSummary = withStyles({
  root: {
    flexDirection: 'row-reverse',
    padding: 0,
    height: '20px',
    minHeight: '20px !important',
    '&$expanded': {
      minHeight: '20px',
    },
  },
  expandIcon: {
    padding: '12px',
  },
  content: {
    margin: '0 !important',
    '&$expanded': {
      margin: '0 !important',
    },
  },
})(AccordionSummary)

export const StyledAccordionDetails = withStyles({
  root: {
    flexDirection: 'column',
    padding: '8px 0',
  },
})(AccordionDetails)

StyledAccordion.displayName = 'StyledAccordion'
StyledAccordionSummary.displayName = 'StyledAccordionSummary'
StyledAccordionDetails.displayName = 'StyledAccordionDetails'
