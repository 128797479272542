import React from 'react'
import { DebouncedState } from 'use-debounce'

import theme from '../../../Shared/Theme/ssTheme'
import { SrOnly } from '../../../Shared/Theme/utilities.styles'
import SearchInput from '../../Shared/SearchInput/SearchInput'

import { StyledLabelSearchBox, StyledSearchBox } from './style'

type SearchBoxInputProps = {
  autoSearch?: boolean
  handleOnBlur: React.FocusEventHandler<HTMLInputElement>
  handleOnFocus: React.FocusEventHandler<HTMLInputElement>
  debouncedHandleSearchFunction: DebouncedState<(inputText: string) => void>
  initialValue: string | undefined
  onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void | boolean
  onKeyPress?: (evt: React.KeyboardEvent<HTMLInputElement>) => void
  setError?: (error: boolean) => void
  error?: boolean
  maxCharLimit?: number
  tabIndex?: number
  iconTabIndex?: number
  resourcesZipCode: string
}

const SearchBoxInput = ({
  handleOnBlur,
  handleOnFocus,
  debouncedHandleSearchFunction,
  initialValue,
  autoSearch,
  onChange,
  onKeyPress,
  setError,
  error,
  maxCharLimit,
  tabIndex,
  iconTabIndex,
  resourcesZipCode,
}: SearchBoxInputProps) => {
  return (
    <StyledSearchBox>
      <StyledLabelSearchBox hasError={error || false}>
        <SrOnly>Search for local resources</SrOnly>
        <SearchInput
          autoSearch={autoSearch}
          handleOnBlur={handleOnBlur}
          handleOnFocus={handleOnFocus}
          handleSearch={debouncedHandleSearchFunction}
          iconFill={theme.palette.primary.dark}
          id={'cypress-clear-text-input'}
          initialValue={initialValue}
          onChange={onChange}
          placeholder={resourcesZipCode ? 'ZIP Code or keyword' : 'ZIP Code'}
          onKeyPress={onKeyPress}
          maxCharLimit={maxCharLimit}
          setError={setError}
          error={error}
          tabIndex={tabIndex}
          iconTabIndex={iconTabIndex}
        />
      </StyledLabelSearchBox>
    </StyledSearchBox>
  )
}

export default SearchBoxInput
