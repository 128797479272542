import React from 'react'
import {
  formatToDollarsEven,
  isFeatureToggleOn,
  toCapitalized,
} from 'Shared/helpers'

import { StyledBenefitAmount, StyledBenefitsSpan } from './style'

interface RecurringBenefitAmountProps {
  frequency: string
  recurringAmount: number
  alreadyReceivingValue?: boolean
}
let isFeatureToggleOnValue = false
isFeatureToggleOnValue = isFeatureToggleOn('already_receiving_benefits')
const isEligibilityPage = window.location.pathname.includes('/eligibility')
const RecurringBenefitAmount = ({
  frequency,
  recurringAmount,
  alreadyReceivingValue = false,
}: RecurringBenefitAmountProps) =>
  recurringAmount && frequency && frequency !== 'NONRECURRENT' ? (
    <div>
      <StyledBenefitsSpan>{`${toCapitalized(frequency)}: `}</StyledBenefitsSpan>
      <StyledBenefitAmount highlight={true} small>
        {alreadyReceivingValue && isFeatureToggleOnValue
          ? 'Already receiving'
          : formatToDollarsEven(recurringAmount)}
      </StyledBenefitAmount>
    </div>
  ) : !isEligibilityPage && isFeatureToggleOnValue ? (
    <>
      <StyledBenefitsSpan>
        {`${toCapitalized('Monthly')}: `}{' '}
        <StyledBenefitAmount highlight={true} small>
          {alreadyReceivingValue && isFeatureToggleOnValue
            ? 'Already receiving'
            : formatToDollarsEven(recurringAmount)}
        </StyledBenefitAmount>
      </StyledBenefitsSpan>
    </>
  ) : null

export default RecurringBenefitAmount
