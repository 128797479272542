import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useQuery } from '@apollo/client'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { compose } from '@reduxjs/toolkit'
import { toggleAllCategories, toggleIsOnCategories } from 'Actions/preScreener'
import { ME } from 'Components/Auth/Hooks/gql'
import PreScreenerActions from 'Components/PreScreener/PreScreenerActions'
import { StyledCategoriesWrapper } from 'Components/PreScreener/style'
import { StyledForm } from 'Components/Screener/style'
import { StyledMainHeading, StyledParagraph } from 'Components/Screener/style'
import { StyledCard } from 'Components/Shared/HeaderWithContainerAndCard/style'
import ProgressSideBar from 'Components/Shared/ProgressSideBar/ProgressSideBar'
import {
  StyledHouseholdMembersList,
  StyledHouseholdMembersListItem,
} from 'Components/Shared/ReduxForm/style'
import { formValueSelector, reduxForm } from 'redux-form'
import {
  DESKTOP_MEDIA_QUERY,
  MOBILE_MEDIA_QUERY,
  PRE_SCREENER_FORM_NAME,
} from 'Shared/constants'
import { ssTheme } from 'Shared/Theme/ssTheme'
import theme from 'Shared/Theme/ssTheme'
import { Col, Container, Row } from 'styled-bootstrap-grid'

import PreScreenerCategory from './PreScreenerCategory'

const renderCategories = (categories) => {
  return categories.map((category) => {
    return <PreScreenerCategory key={category.id} icon={category} />
  })
}

const PreScreenerCategories = (props) => {
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY })
  const {
    handleSubmit,
    toggleIsOnCategories,
    categories,
    allCategoriesSelected,
    toggleAllCategories,
    selectedCategories,
  } = props

  const isDesktop = useMediaQuery({
    query: DESKTOP_MEDIA_QUERY,
  })

  const { data, loading } = useQuery(ME)

  const isACaseManager = data && data?.me?.isACaseManager

  useEffect(() => {
    toggleIsOnCategories()
    return () => {
      toggleIsOnCategories()
    }
  }, [])

  return (
    <StyledCategoriesWrapper>
      <Container fluid className={isDesktop ? 'col-no-padding' : ''}>
        <Row>
          {isDesktop && (
            <ProgressSideBar
              preSelectedCategoryId={0}
              preProgressPercentage={90}
              position='absolute'
            />
          )}
        </Row>
        <StyledCard
          padding={isDesktop ? '0' : '2.5rem 0'}
          height='calc(100vh - 10.4rem)'
        >
          <Row>
            <Col lg='10' lgOffset='1'>
              <StyledForm
                onSubmit={handleSubmit}
                margin='2rem auto'
                height='auto'
              >
                <StyledMainHeading id='mainContent' $legend={true}>
                  Terrific! What can we help you find assistance with?
                </StyledMainHeading>

                <StyledParagraph color={theme.palette.color.black}>
                  If you'd like, select one or more categories to let us know
                  what kind of help you're looking for. Don't worry – selections
                  aren't required and won't affect what you may be eligible for.
                </StyledParagraph>

                <MuiThemeProvider theme={ssTheme}>
                  <FormGroup>
                    <FormControlLabel
                      style={{ padding: '.8rem 0' }}
                      control={
                        <Checkbox
                          color='primary'
                          checked={allCategoriesSelected}
                          onChange={() =>
                            toggleAllCategories(
                              allCategoriesSelected,
                              selectedCategories,
                              categories
                            )
                          }
                          style={{
                            padding: '0 .7rem',
                          }}
                          name='select-all-categories'
                        />
                      }
                      label='Select all'
                    />
                  </FormGroup>
                </MuiThemeProvider>

                <StyledHouseholdMembersListItem
                  margin={isMobile ? '1.6rem 0 6rem 0' : ''}
                >
                  <StyledHouseholdMembersList>
                    <Row>{renderCategories(categories)}</Row>
                  </StyledHouseholdMembersList>
                </StyledHouseholdMembersListItem>
                <PreScreenerActions
                  isACaseManager={isACaseManager}
                  loading={loading}
                />
              </StyledForm>
            </Col>
          </Row>
        </StyledCard>
      </Container>
    </StyledCategoriesWrapper>
  )
}

const selector = formValueSelector(PRE_SCREENER_FORM_NAME)

const mapStateToProps = (state, ownProps) => {
  const selectedCategories = selector(state, 'category') || {}

  return {
    selectedCategories,
    allCategoriesSelected: ownProps.categories.every(
      (category) => selectedCategories[`key-${category.id}`]
    ),
  }
}

export default compose(
  connect(mapStateToProps, { toggleIsOnCategories, toggleAllCategories }),
  reduxForm({
    form: PRE_SCREENER_FORM_NAME,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })
)(PreScreenerCategories)
