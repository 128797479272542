import React from 'react'
import ModalTemplate from 'Components/CaseManagement/Modals/Shared/ModalTemplate'
import NotStarted from 'Components/ClientPortal/MyBenefits/UpdateBenefitModal/screens/NotStarted'
import { closeEditBenefitStatusModal } from 'Reducers/myBenefitsSlice'
import { useAppDispatch, useAppSelector } from 'Store/hooks'

const UpdateBenefitModal = () => {
  const { benefitName, benefitStatus, caseId, openEditCaseStatusModal } =
    useAppSelector((state) => state.myBenefits)
  const dispatch = useAppDispatch()
  const handleCancel = () => {
    dispatch(closeEditBenefitStatusModal())
  }
  return (
    <ModalTemplate
      open={openEditCaseStatusModal}
      heading={''}
      hasXToClose={true}
      handleCancel={handleCancel}
    >
      {benefitStatus === 'NOT_STARTED' && (
        <NotStarted
          benefitName={benefitName}
          caseId={caseId}
          handleCancel={handleCancel}
        />
      )}
    </ModalTemplate>
  )
}

export default UpdateBenefitModal
