import React from 'react'
import FilterMenu from 'Components/LocalResources/Filtering/FilterMenu/FilterMenu'
import SearchBox from 'Components/LocalResources/SearchBox/SearchBox'
import ActionToggle from 'Components/Shared/ActionToggle/ActionToggle'
import Menu from 'Components/Shared/Menu/Menu'
import SearchBar from 'Components/Shared/SearchBar/SearchBar'
import SecondaryActionBar from 'Components/Shared/SecondaryActionBar/SecondaryActionBar'
import { StyledMain } from 'Pages/Results/style'
import { handleToggleFilterMenu } from 'Reducers/uiSlice'
import {
  categoriesBlackIconPath,
  categoriesCloseIconPath,
} from 'Shared/Icons/CategoryIcons/CategoriesFilterIconsPath'
import { SrOnly } from 'Shared/Theme/utilities.styles'
import { useAppDispatch, useAppSelector } from 'Store/hooks'

// import SearchFeedback from './Components/SearchFeedback'
import LocationsList from './LocationsList/LocationsList'
import LocationsMap from './LocationsMap/LocationsMap'

type MobileLocationsProps = {
  registeredZipCode: string
  searchTerm: string
  showSearchThisAreaButton: boolean
  setSearchThisArea: (searchThisArea: boolean) => void
  setZipCodeError: (error: boolean) => void
  zipCodeError: boolean
}

const MobileLocations = ({
  registeredZipCode,
  searchTerm,
  showSearchThisAreaButton,
  setSearchThisArea,
  setZipCodeError,
  zipCodeError,
}: MobileLocationsProps) => {
  const dispatch = useAppDispatch()
  const { showLocationList, showFilterMenu } = useAppSelector(
    (state) => state.ui
  )

  return (
    <>
      <Menu />
      <StyledMain>
        <SrOnly>
          <h1>Browse Resources</h1>
        </SrOnly>
        <SearchBar>
          <div className='map-search-bar'>
            <SearchBox
              zipCodeError={zipCodeError}
              setZipCodeError={setZipCodeError}
              initialValue={searchTerm || registeredZipCode}
            />
            <ActionToggle
              filterInMobile={true}
              showMenu={showFilterMenu}
              activeIconPath={categoriesCloseIconPath}
              activeLabel={'filters'}
              inactiveIconPath={categoriesBlackIconPath}
              onClick={() => {
                dispatch(handleToggleFilterMenu())
              }}
              inactiveLabel={'filters'}
              fillIcon='none'
            />
          </div>
          {zipCodeError && (
            <p className='error-message'>Please enter a valid ZIP Code.</p>
          )}
          {/* Commenting it untill the Backend get it done */}
          {/* <SearchFeedback /> */}
        </SearchBar>
        <FilterMenu />

        {showLocationList && <LocationsList />}
        {!showLocationList && (
          <LocationsMap
            showSearchThisAreaButton={showSearchThisAreaButton}
            setSearchThisArea={setSearchThisArea}
          />
        )}
        <SecondaryActionBar />
      </StyledMain>
    </>
  )
}

export default MobileLocations
