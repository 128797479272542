import React from 'react'
import MediaQuery from 'react-responsive'
import PreScreenerBackButton from 'Components/PreScreener/PreScreenerBackButton'
import {
  ReverseCol,
  StyledParentButtonsActionsInMobile,
  StyledParentPreScreenerButton,
} from 'Components/Screener/style'
import ColDynamicCtaButton from 'Components/Shared/ReduxForm/ColDynamicCtaButton'
import {
  DESKTOP_MEDIA_QUERY,
  MOBILE_MEDIA_QUERY,
  TABLET_MEDIA_QUERY,
} from 'Shared/constants'
import { Row } from 'styled-bootstrap-grid'

const PreScreenerActions = (props) => {
  return (
    <>
      <MediaQuery query={MOBILE_MEDIA_QUERY}>
        <StyledParentButtonsActionsInMobile>
          <ReverseCol>
            {props.isACaseManager || props.loading ? (
              ''
            ) : (
              <StyledParentPreScreenerButton width='100%'>
                <PreScreenerBackButton
                  smallerMargin='0'
                  margin='0'
                  width='100%'
                  type='submit'
                >
                  Back
                </PreScreenerBackButton>
              </StyledParentPreScreenerButton>
            )}
            <StyledParentPreScreenerButton width='100%'>
              <ColDynamicCtaButton
                smallerMargin='0'
                disabled={props.invalid || props.emptyDay}
              />
            </StyledParentPreScreenerButton>
          </ReverseCol>
        </StyledParentButtonsActionsInMobile>
      </MediaQuery>

      <MediaQuery query={TABLET_MEDIA_QUERY}>
        <Row>
          <ReverseCol>
            {props.isACaseManager || props.loading ? (
              ''
            ) : (
              <StyledParentPreScreenerButton>
                <PreScreenerBackButton
                  margin='0 1rem 0 0'
                  width='180px'
                  type='submit'
                >
                  Back
                </PreScreenerBackButton>
              </StyledParentPreScreenerButton>
            )}

            <StyledParentPreScreenerButton>
              <ColDynamicCtaButton
                width='180px'
                disabled={props.invalid || props.emptyDay}
              />
            </StyledParentPreScreenerButton>
          </ReverseCol>
        </Row>
      </MediaQuery>

      <MediaQuery query={DESKTOP_MEDIA_QUERY}>
        <Row>
          <ReverseCol>
            {props.isACaseManager || props.loading ? (
              ''
            ) : (
              <StyledParentPreScreenerButton
                width={props.backButtonWidth ? props.backButtonWidth : '180px'}
              >
                <PreScreenerBackButton
                  margin={props.backMargin || '0'}
                  width={props.backWidth || '100%'}
                  padding='1rem 5rem 1rem 5.3rem'
                  type='submit'
                >
                  Back
                </PreScreenerBackButton>
              </StyledParentPreScreenerButton>
            )}
            <StyledParentPreScreenerButton
              width={
                props.continueButtonWidth ? props.continueButtonWidth : '180px'
              }
            >
              <ColDynamicCtaButton
                width={props.continueWidth}
                disabled={props.invalid || props.emptyDay}
              />
            </StyledParentPreScreenerButton>
          </ReverseCol>
        </Row>
      </MediaQuery>
    </>
  )
}

export default PreScreenerActions
