import React from 'react'
import MediaQuery, { useMediaQuery } from 'react-responsive'
import { useParams } from 'react-router-dom'
import FilterMenu from 'Components/LocalResources/Filtering/FilterMenu/FilterMenu'
import LocationsMap from 'Components/LocalResources/LocationsMap/LocationsMap'
import SearchBox from 'Components/LocalResources/SearchBox/SearchBox'
import ActionToggle from 'Components/Shared/ActionToggle/ActionToggle'
import SearchBar from 'Components/Shared/SearchBar/SearchBar'
import { StyledMain } from 'Pages/Results/style'
import { handleToggleFilterMenu } from 'Reducers/uiSlice'
import { TABLET_MEDIA_QUERY } from 'Shared/constants'
import {
  categoriesBlackIconPath,
  categoriesWhiteIconPath,
} from 'Shared/Icons/CategoryIcons/CategoriesFilterIconsPath'
import { SrOnly } from 'Shared/Theme/utilities.styles'
import { useAppDispatch, useAppSelector } from 'Store/hooks'

// import SearchFeedback from './Components/SearchFeedback'
import LocationsList from './LocationsList/LocationsList'
import {
  StyledTabletAndDesktopLeftColumn,
  StyledTabletContainer,
} from './style'

type LocationsViewportPageProps = {
  registeredZipCode: string
  searchTerm: string
  setReferralData?: (referralData: Record<string, string>) => void
  showSearchThisAreaButton: boolean
  setSearchThisArea: (searchThisArea: boolean) => void
  setZipCodeError: (error: boolean) => void
  zipCodeError: boolean
}

const DesktopLocations = ({
  registeredZipCode,
  searchTerm,
  showSearchThisAreaButton,
  setSearchThisArea,
  setZipCodeError,
  zipCodeError,
}: LocationsViewportPageProps) => {
  const { showLeftColumn, showFilterMenu } = useAppSelector((state) => state.ui)
  const params = useParams()
  const dispatch = useAppDispatch()
  const isTablet = useMediaQuery({
    query: TABLET_MEDIA_QUERY,
  })

  return (
    <StyledMain>
      <StyledTabletContainer>
        <StyledTabletAndDesktopLeftColumn
          showLeftColumn={showLeftColumn}
          aria-label='Locations List Column'
        >
          <SrOnly>
            <h1>Browse Resources</h1>
          </SrOnly>
          <MediaQuery query={TABLET_MEDIA_QUERY}>
            {(matches) =>
              (!matches || !params.locationId) && (
                <SearchBar>
                  <div className='map-search-bar'>
                    <SearchBox
                      className='map-search-box'
                      initialValue={searchTerm || registeredZipCode}
                      setZipCodeError={setZipCodeError}
                      zipCodeError={zipCodeError}
                      tabIndex={showLeftColumn ? 1 : -1}
                      iconTabIndex={showLeftColumn ? 2 : -1}
                    />
                    {isTablet && (
                      <ActionToggle
                        activeIconPath={categoriesWhiteIconPath}
                        activeLabel={'filters'}
                        inactiveIconPath={categoriesBlackIconPath}
                        onClick={() => {
                          dispatch(handleToggleFilterMenu())
                        }}
                        inactiveLabel={'filters'}
                        showMenu={showFilterMenu}
                        fillIcon='none'
                      />
                    )}
                  </div>
                  {zipCodeError && (
                    <p className='error-message'>
                      Please enter correct ZIP Code.
                    </p>
                  )}
                  {/* Commenting it untill the Backend get it done */}
                  {/* <SearchFeedback /> */}
                </SearchBar>
              )
            }
          </MediaQuery>
          <FilterMenu />
          <LocationsList />
        </StyledTabletAndDesktopLeftColumn>
        <LocationsMap
          showSearchThisAreaButton={showSearchThisAreaButton}
          setSearchThisArea={setSearchThisArea}
        />
      </StyledTabletContainer>
    </StyledMain>
  )
}

export default DesktopLocations
