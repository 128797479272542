import { media } from 'styled-bootstrap-grid'
import styled from 'styled-components'

export const StyledTabletAndDesktopSearchBar = styled.div`
  width: 100%;
  padding: 1.6em;
  padding-top: 24px;
  padding-bottom: 16px;
  z-index: 0;
  position: relative;
  .map-search-bar {
    display: flex;
    padding: 0;
    width: 100%;
  }

  .error-message {
    color: #f64744;
    font-size: 1rem;
    font-weight: 500;
    margin-top: 0.5rem;
    ${media.smaller`
      text-align: center;
  `}
  }
  ${media.smaller`
    padding: 1rem 1.6rem;
  `}
  @media only screen and (min-width: 481px) and (max-width: 1024px) {
    padding: 1.5rem 1rem;
  }
`

StyledTabletAndDesktopSearchBar.displayName = 'StyledTabletAndDesktopSearchBar'
