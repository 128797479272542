import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { updateHasCustomQuestions } from 'Actions/screener'
import { useIsValidOrganizationAndLocationSlugs } from 'Components/App/Hooks'
import ClientAuth from 'Components/Auth/ClientAuth'
import { ME } from 'Components/Auth/Hooks/gql'
import useOtp from 'Components/Auth/Hooks/useOtp'
import useAuthenticationPath from 'Components/PreScreener/Hooks/useAuthenticationPath'
import useStep from 'Components/PreScreener/Hooks/useStep'
import PreScreenerBirthday from 'Components/PreScreener/PreScreenerBirthday'
import PreScreenerCategories from 'Components/PreScreener/PreScreenerCategories'
import PreScreenerEmailOrPhone from 'Components/PreScreener/PreScreenerEmailOrPhone'
import PreScreenerLocation from 'Components/PreScreener/PreScreenerLocation'
import mockCategories from 'Components/PreScreener/preScreenerMockCategoriesPayload'
import PreScreenerName from 'Components/PreScreener/PreScreenerName'
import PreScreenerSectionIntroduction from 'Components/PreScreener/PreScreenerSectionIntroduction'
import SkipVerificationModal from 'Components/PreScreener/SkipVerificationModal/ConfirmSkipVerificationModal'
import useCustomScreenerQuestions from 'Components/Screener/Hooks/useCustomScreenerQuestions'
import HeaderWithContainerAndCard from 'Components/Shared/HeaderWithContainerAndCard/HeaderWithContainerAndCard'
import useHandlePatchClient from 'Components/Shared/Hooks/useHandlePatchClient'
import PageNotFound from 'Pages/PageNotFound/PageNotFound'
import { isFeatureToggleOn } from 'Shared/helpers'

import { PreScreenerContext } from './PreScreenerProvider'

const PreScreener = () => {
  const { isValidUrl, isLoading } = useIsValidOrganizationAndLocationSlugs()
  const navigate = useNavigate()

  const [content, setContent] = useState(null)

  const {
    preScreenerState: { modal },
    resetContext,
  } = useContext(PreScreenerContext)

  const {
    handleAuthenticateAndContinue,
    handleCreateClient,
    nextStep,
    handleCreateClientAndSkipOtp,
  } = useAuthenticationPath()

  const dispatch = useDispatch()

  const { data } = useQuery(ME)

  const isACaseManager = data && data?.me?.isACaseManager

  const { hasCustomFields, loading: customFieldsResponseLoading } =
    useCustomScreenerQuestions()

  useEffect(() => {
    if (!customFieldsResponseLoading) {
      dispatch(updateHasCustomQuestions(hasCustomFields))
    }
  }, [customFieldsResponseLoading, hasCustomFields, dispatch])

  const { step } = useStep()

  const { handleRequestOtp } = useOtp()

  const handlePatchClient = useHandlePatchClient(nextStep)
  const handlePatchClientLocation = useHandlePatchClient(() =>
    navigate('/benefits-received')
  )

  const handleSubmitEmailOrPhone = (formValues) =>
    formValues.verification_skipped
      ? handleCreateClientAndSkipOtp({ formData: formValues })
      : handleCreateClient({ formData: formValues })

  useEffect(() => {
    switch (step) {
      case 1:
        setContent(<PreScreenerName onSubmit={nextStep} />)
        break
      case 2:
        setContent(<PreScreenerBirthday onSubmit={nextStep} />)
        break
      case 3:
        setContent(<PreScreenerSectionIntroduction onSubmit={nextStep} />)
        break
      case 4:
        setContent(
          <PreScreenerEmailOrPhone onSubmit={handleSubmitEmailOrPhone} />
        )
        break
      case 5:
        setContent(
          <ClientAuth
            handleRequestOtp={handleRequestOtp}
            onSubmit={(formData) => {
              return handleAuthenticateAndContinue({ formData, isACaseManager })
            }}
          />
        )
        break
      case 6:
        setContent(
          <PreScreenerCategories
            categories={mockCategories}
            onSubmit={handlePatchClient}
          />
        )
        break
      case 7:
        setContent(
          <PreScreenerLocation
            onSubmit={(...params) => {
              if (isFeatureToggleOn('already_receiving_benefits')) {
                handlePatchClientLocation(...params)
              } else {
                handlePatchClient(...params)
              }
            }}
          />
        )
        break
      default:
        setContent(null)
    }
  }, [step])

  if (isLoading) return null

  if (!isValidUrl) return <PageNotFound />

  return (
    <HeaderWithContainerAndCard padding={'0'}>
      {content}
      <SkipVerificationModal
        open={modal === 'SKIP_VERIFY'}
        resetContext={resetContext}
      />
    </HeaderWithContainerAndCard>
  )
}

export default PreScreener
