import React from 'react'
import { useDispatch } from 'react-redux'
import { Maybe, Person } from '__generated__/graphql'
import CaseTitle from 'Components/ClientPortal/MyBenefits/CaseCard/CaseTitle'
import CustomCaseStatus from 'Components/ClientPortal/MyBenefits/CaseCard/CustomCaseStatus/CustomCaseStatus'
import {
  StyledBenefitStatusWrapper,
  StyledCasesCard,
} from 'Components/ClientPortal/MyBenefits/style'
import {
  openBenefitInfoModal,
  openEditBenefitStatusModal,
} from 'Reducers/myBenefitsSlice'
import { isFeatureToggleOn, isSpacebarOrEnterKey } from 'Shared/helpers'

type CaseCardProps = {
  name: string
  eligibility: string
  status: 'ACTIVE' | 'INACTIVE'
  id: string
  people: Maybe<Person[]> | undefined
  description: string
  openEligibilityKey: string
}
const CaseCard = ({
  name,
  eligibility,
  status,
  id,
  people,
  description,
  openEligibilityKey,
}: CaseCardProps) => {
  const dispatch = useDispatch()

  const handleEdit = (name, status, id) => {
    dispatch(
      openEditBenefitStatusModal({
        benefitName: name,
        benefitStatus: status,
        caseId: id,
      })
    )
  }
  const enableEdit = isFeatureToggleOn('client_portal_update_benefits')
  return (
    <StyledCasesCard
      key={id}
      data-testid={'service-case-card'}
      id={`service-case-card-${id}`}
    >
      <CustomCaseStatus status={status} />
      <CaseTitle
        name={name}
        handleClickOpen={() => {
          dispatch(
            openBenefitInfoModal({
              benefitName: name,
              benefitDescription: description,
              openEligibilityKey,
            })
          )
        }}
      />
      <StyledBenefitStatusWrapper>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            lineHeight: '1.25',
          }}
        >
          <div>Benefit Status:</div>
          <div className='StyledBenefitStatusWrapper__info'>{eligibility}</div>
        </div>
        {enableEdit && (
          <button
            tabIndex={0}
            style={{
              color: 'green',
              cursor: 'pointer',
              fontSize: '1rem',
              background: '#ffffff',
              border: 'none',
              fontWeight: 'bold',
            }}
            onClick={() => handleEdit(name, status, id)}
            onKeyDown={(evt) => {
              if (isSpacebarOrEnterKey(evt.keyCode)) {
                handleEdit(name, status, id)
              }
            }}
          >
            Update
          </button>
        )}
      </StyledBenefitStatusWrapper>
      <StyledBenefitStatusWrapper>
        {people && <span>`For ${people.join(', ')}`</span>}
      </StyledBenefitStatusWrapper>
    </StyledCasesCard>
  )
}

export default CaseCard
