import { BenefitStatus } from '__generated__/graphql'
import { PillBase } from 'Components/CaseManagement/Header/Notifications/DropdownNotificationStyles/Pill'
import styled, { css } from 'styled-components'

const getColorByStatus = (benefitStatus: BenefitStatus): object => {
  const colorObject: {
    backgroundColor: string
    color: string
    IconColor?: string
  } = {
    backgroundColor: '',
    color: '',
  }
  switch (benefitStatus) {
    case 'CLOSED':
      colorObject.color = '#08674A'
      colorObject.backgroundColor = '#EEF9F6'
      break
    case 'CONFIRMED':
      colorObject.color = '#08674A'
      colorObject.backgroundColor = '#EEF9F6'
      break
    case 'IN_PROGRESS':
      colorObject.color = '#151515'
      colorObject.backgroundColor = '#FFF8E1'
      colorObject.IconColor = '#FFB629'
      break
    case 'NOT_APPROVED':
      colorObject.color = '#151515'
      colorObject.backgroundColor = '#FDE6E5'
      colorObject.IconColor = '#E31010'
      break
    case 'NOT_STARTED':
      colorObject.color = '#595959'
      colorObject.backgroundColor = '#F9F9F9'
      break
    default: //'ALREADY_RECEIVING' (not yet implemented on BE or in TS type)
      colorObject.color = '#595959'
      colorObject.backgroundColor = '#F9F9F9'
  }
  return colorObject
}

export const StyledActivationStatus = styled(PillBase)<{
  $status: BenefitStatus
}>`
  min-width: unset;
  margin-top: 0;
  color: #ffffff;
  font-family: 'Noto Sans HK', sans-serif;
  font-weight: 600;
  font-size: 12px;
  border-radius: 24px;
  padding: 7px 12px;
  margin-bottom: 16px;
  height: unset;
  line-height: unset;
  .customIcon {
    margin-right: 4px;
    svg {
      width: 17px;
      height: unset;
      fill: ${({ $status }) => getColorByStatus($status).IconColor};
    }
  }
  .customText {
    margin-bottom: 3px;
  }
  ${({ $status }) => css`
    background: ${getColorByStatus($status).backgroundColor};
    color: ${getColorByStatus($status).color};
  `}
`

StyledActivationStatus.displayName = 'StyledActivationStatus'
