import styled, { css } from 'styled-components'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  padding-top: 0.4rem;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  max-height: 100%;
  padding-bottom: 1rem;
`

export const StyledLocationCardContainer = styled.button<{
  $noLocationFound?: boolean
}>`
  padding: 0;
  text-align: left;
  background: unset;
  border: unset;
  width: 100%;
  ${({ $noLocationFound }) =>
    $noLocationFound &&
    css`
      padding: 1rem 1.875rem;
      line-height: 1.5;
    `}
`

export const StyledAlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  padding-top: 0.4rem;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: unset;
  border: unset;
  width: 100%;
`

export const StyledLocationsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

StyledContainer.displayName = 'StyledContainer'
StyledAlertContainer.displayName = 'StyledAlertContainer'
StyledLocationsList.displayName = 'StyledLocationsList'
