import React from 'react'
import { useMutation } from '@apollo/client'
import {
  ACTIVATE_INACTIVE_CASE,
  GET_CASES,
} from 'Components/ClientPortal/MyBenefits/gql'
import { OutcomeButton } from 'Components/Outcome/OutcomeButtons'

const NotStarted = ({
  benefitName,
  caseId,
  handleCancel,
}: {
  benefitName: string
  caseId: string
  handleCancel: () => void
}) => {
  const [handleActivateCase] = useMutation(ACTIVATE_INACTIVE_CASE, {
    variables: {
      serviceCaseId: caseId,
    },
    refetchQueries: [GET_CASES],
  })
  return (
    <section className='form-container'>
      <article className='screen-one-header'>
        <h1 style={{ color: '#08674a', textAlign: 'center' }}>
          Did you apply for {benefitName}?
        </h1>
        <p style={{ textAlign: 'center' }}>
          Your feedback will help us improve the benefits application process.
        </p>
      </article>
      <ul style={{ listStyleType: 'none' }}>
        <li style={{ marginBottom: '1rem' }}>
          <OutcomeButton
            type='primary'
            label='Yes'
            onClick={() => {
              // call mutation
              // close modal
              handleActivateCase().then(handleCancel)
            }}
          />
        </li>
        <li style={{ marginBottom: '1rem' }}>
          <OutcomeButton type='secondary' label='No' onClick={handleCancel} />
        </li>
        <li>
          <OutcomeButton
            type='tertiary'
            label='Not yet'
            onClick={handleCancel}
          />
        </li>
      </ul>
    </section>
  )
}

export default NotStarted
